import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavHeaderModule } from './nav-header/nav-header.module';
import { FooterToyotaModule } from './footer-toyota/footer-toyota.module';
import { CookiesAlertModule } from './cookies-alert/cookies-alert.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { WidgetRrssModule } from './widget-rrss/widget-rrss.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NavHeaderModule,
        FooterToyotaModule,
        CookiesAlertModule,
        GoogleMapsModule,
        WidgetRrssModule,
        NgbModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
