<div class="header-buscador">
  <div class="container" *ngIf="search">
    <div class="row header-buscador-resultados" *ngIf="resultados">
      <div class="row texto-resultado">Resultados: {{resultados.length}}</div>
      <div class="row">
        <a [href]="resultado.url" *ngFor="let resultado of resultados" class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="row d-flex justify-content-center align-middle">
                <div class="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                    <h2 class="title-search">{{resultado.titulo}}</h2>
                </div>
                <div class="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8">
                    <img [src]="resultado.imagen" alt="{{resultado.titulo}}" class="buscador-img"/>
                </div>
                <!--<div class="col-10">
                    <p>{{resultado.url}}</p>
                </div>-->
            </div>
        </a>
      </div>
      
    </div>
    <div class="header-buscador-resultados col-10" *ngIf="!resultados">
      <p class="texto-resultado">No se encontro resultados para: <b>{{search}}</b></p>
    </div>
  </div>
</div>

