import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {BuscadorService} from "../../servicios/buscador.service";
import {first} from "rxjs/operators";


@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnChanges {
  @Input() search: any;
  resultados:any;
  
  constructor(private servicio: BuscadorService) { }

  ngOnInit(): void {
    //this.functionSearch();
  }

  functionSearch() {
    this.servicio.buscar(this.search).pipe(first()).subscribe(
        data => {
          this.resultados = data;
          //console.log(this.resultados);
        }, error => {
          console.log(error);
        }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['search'].currentValue !== undefined) {
      this.functionSearch();
    }
  }

}
