import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetRrssRoutingModule } from './widget-rrss-routing.module';
import { WidgetRrssComponent } from './widget-rrss.component';


@NgModule({
  exports: [
    WidgetRrssComponent
  ],
  declarations: [
    WidgetRrssComponent
  ],
  imports: [
    CommonModule,
    WidgetRrssRoutingModule
  ]
})
export class WidgetRrssModule { }
