import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavHeaderRoutingModule } from './nav-header-routing.module';
import { NavHeaderComponent } from './nav-header.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { MobileComponent } from './mobile/mobile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  exports: [NavHeaderComponent],
  declarations: [
    NavHeaderComponent,
    BuscadorComponent,
    MobileComponent
  ],
  imports: [
    CommonModule,
    NavHeaderRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbNavModule,
    NgbDropdownModule
  ]
})
export class NavHeaderModule { }
