<div class="rrss">
    <div [@flyInOut] class="container-toyowidget">
        <div class="capsule">
            <div class="rrss-nm">WhatsApp</div>
            <a class="toyowidget wp" href="https://api.whatsapp.com/send?phone=+59169831219&text=Hola,%20estaba%20en%20la%20página%20web%20y%20tengo%20una%20consulta" target="_blank">
                <img [src]="imgwhatsapp" width="40" height="40" alt="Icono de llamadas" class="widget-wp"/>
            </a>
        </div>
    </div>
    <div class="rrss-widgets contenedores" *ngIf="visible">
        <div [@flyInOut] class="container-toyowidget">
            <div class="capsule">
                <div class="rrss-nm">WhatsApp</div>
                <a class="toyowidget wp" href="https://api.whatsapp.com/send?phone=+59169831219&text=Hola,%20estaba%20en%20la%20página%20web%20y%20tengo%20una%20consulta" target="_blank">
                    <img [src]="imgwhatsapp" width="40" height="40" alt="Icono de whatsapp" class="widget-wp"/>
                </a>
            </div>
        </div>
        <div [@fadeInOut] class="container-toyowidget">
            <div class="capsule">
                <div class="rrss-nm">Facebook Messenger</div>
                <a class="toyowidget ms" href="https://m.me/203977802962086" target="_blank">
                    <img [src]="imgfacebook" width="40" height="40" alt="Icono de Messenger" class="widget-wp"/>
                </a>
            </div>
        </div>
        <div [@fadeInOut] class="container-toyowidget">
            <div class="capsule">
                <div class="rrss-nm">Llamada</div>
                <a class="toyowidget call"  href="tel:800175200" target="_blank">
                    <img [src]="imgllamada" width="40" height="40" alt="Icono de llamada" class="widget-wp"/>
                </a>
            </div>
        </div>
    </div>
    <div class="container-toyowidget contenedores" id="toyoclose" *ngIf="visible"  (click)="close()">
        <div class="capsule">
            <a class="toyowidget ty">
                <img [src]="imgcerrarchat" width="40" height="40" alt="Icono de cerrar" class="widget-wp"/>
            </a>
        </div>
    </div>

    <div class="container-toyowidget contenedores" id="toyoopen" *ngIf="!visible" (click)="open()">
        <div class="capsule">
            <a class="toyowidget ty">
                <img [src]="imgchat" width="40" height="40" alt="Icono de live chat" class="widget-wp"/>
            </a>
        </div>
    </div>
    <div class="container-toyowidget" *ngIf="!visible">
        <div class="capsule">
            <a class="toyowidget bnb" routerLink="/campanas/plan-renuevate">
                <img [src]="imgrenuevate" width="40" height="40" alt="Icono de live chat" class="widget-wp"/>
            </a>
        </div>
    </div>
    <div class="rrss-text" *ngIf="!visible">
        <div class="rrss-text_com">
            Renuévate
        </div>
    </div>

</div>
