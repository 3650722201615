import { Component, OnInit, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';
import { DataService } from '../servicios/data.service';
import { CloudinaryService } from '../servicios/cloudinary.service';

@Component({
  selector: 'app-footer-toyota',
  templateUrl: './footer-toyota.component.html',
  styleUrls: ['./footer-toyota.component.css']
})
export class FooterToyotaComponent implements OnInit, OnDestroy {

  anio: number;
  grupoModelos:any=[];
  estadoSuscription: Subscription | undefined;

  imglogo:any;
  imgexternalink:any;
  imgfacebook:any;
  imginstagram:any;
  imgyoutube:any;
  imglinkedin:any;
  imgtiktok:any;
  imgcontact:any;


  constructor(private dataService: DataService, private cloudinaryService: CloudinaryService) {
    this.anio = new Date().getFullYear();  
    this.loadImage();
  }

  loadImage() {
    this.imglogo = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913723/production/toyota-bolivia/web/assets/icons/logo.png', {
      width: 'auto',
      height: 30,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.imgfacebook = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913767/production/toyota-bolivia/web/assets/icons/facebook.svg', {
      width: 40,
      height: 'auto',
      fetch_format: 'auto',
      quality: 'auto'
    });
    this.imginstagram = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913767/production/toyota-bolivia/web/assets/icons/instagram.svg', {
      width: 40,
      height: 'auto',
      fetch_format: 'auto',
      quality: 'auto'
    });
    this.imgyoutube = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913801/production/toyota-bolivia/web/assets/icons/youtube.svg', {
      width: 40,
      height: 'auto',
      fetch_format: 'auto',
      quality: 'auto'
    });
    this.imglinkedin = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913802/production/toyota-bolivia/web/assets/icons/linkedin.svg', {
      width: 40,
      height: 'auto',
      fetch_format: 'auto',
      quality: 'auto'
    });
    this.imgtiktok = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913801/production/toyota-bolivia/web/assets/icons/tiktok.svg', {
      width: 40,
      height: 'auto',
      fetch_format: 'auto',
      quality: 'auto'
    });
    this.imgcontact = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913818/production/toyota-bolivia/web/assets/icons/cc-footer.svg', {
      width: 40,
      height: 'auto',
      fetch_format: 'auto',
      quality: 'auto'
    });
    this.imgexternalink = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721914261/production/toyota-bolivia/web/assets/icons/external-link-black.svg', {
      width: 13,
      height: 'auto',
      fetch_format: 'auto',
      quality: 'auto'
    });
  }

  ngOnInit(): void {
    this.estadoSuscription = this.dataService.modelos$.subscribe(estado => {
      let dataModelos = JSON.parse(estado);
      this.grupoModelos = Object.keys(dataModelos);
    });
    
  }

  ngOnDestroy(): void {
    this.estadoSuscription?.unsubscribe();
  }

}
