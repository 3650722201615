import { Injectable } from '@angular/core';
import { Cloudinary } from 'cloudinary-core';

@Injectable({
  providedIn: 'root'
})
export class CloudinaryService {
  private cloudinary: any;

  constructor() {
    this.cloudinary = new Cloudinary({ cloud_name: 'toyosa-sa', secure: true });
  }

  getImageUrl(publicId: string, options: any = {}): string {
    return this.cloudinary.url(publicId, options);
  }
}