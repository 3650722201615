import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';
import { DataService } from 'src/app/servicios/data.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit, OnDestroy {

  dataCompleta:any=[];
  grupoModelos:any=[];
  itemsGrupo:any=[];
  active:any;
  viewModelsBlock:boolean=false;
  search:any;
  viewSearchBlock:boolean=false;
  viewModelsCars:boolean = false;
  orientationTab;
  nombreGrupo:any;
  menuPrincipal:boolean=false;
  isCollapsedPostVenta:boolean = false;
  isCollapsedSobreNosotros:boolean = false;
  topHeader:number=0;
  estadoSuscription: Subscription | undefined;

  constructor(private dataService: DataService) {
    //this.obtenerVehiculos();
    this.active = null;
    this.orientationTab = 'vertical';
    this.viewModelsCars = false;
    this.isCollapsedPostVenta =false;
    this.isCollapsedSobreNosotros = false;
  }

  ngOnInit(): void {
    this.estadoSuscription = this.dataService.modelos$.subscribe(estado => {
      let dataModelos = JSON.parse(estado);
      this.grupoModelos = Object.keys(dataModelos);
      this.dataCompleta = dataModelos;
      //this.obtenerItems(this.grupoModelos[0]);
    });
  }

  ngOnDestroy(): void {
    this.estadoSuscription?.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  myScroll($event: any) {
    this.topHeader = window.scrollY;

    if(this.topHeader>0) {
      let elemento:any = document.getElementById("header");
      elemento.style.top = "0";
    }
  }
  
  viewMenuPrincipal() {
    this.menuPrincipal = true;
    this.search = '';
  }
  noViewMenuPrincipal() {
    this.menuPrincipal = false;
  }
  noViewSearch() {
    this.search = '';
  }

  // Servicio que obtiene la lista de vehiculos
  obtenerVehiculos():void {
    if (localStorage.getItem('vehiculos-header') != null) {
      const datamodels: any = localStorage.getItem('vehiculos-header');
      let dataModelos = JSON.parse(datamodels);
      this.grupoModelos = Object.keys(dataModelos);
      this.dataCompleta = dataModelos;
      this.obtenerItems(this.grupoModelos[0]);
    }
    /*this.vehiculosService.getAutomovilesAll().pipe(first()).subscribe(
        data => {
          this.grupoModelos = Object.keys(data);
          this.dataCompleta = data;
          this.obtenerItems(this.dataCompleta[this.grupoModelos[0]]);   
        }, error => {
        }
    );  */
  }
  obtenerItems(index: string) {
    this.nombreGrupo = index;
    this.viewModelsBlock = false;
    this.viewModelsCars = true;
    this.itemsGrupo = this.dataCompleta[index].modelos;
    //console.log(this.itemsGrupo);
  }


  viewModels() {
    if(this.viewModelsBlock){
      this.viewModelsBlock = false;
    } else {
      this.viewModelsBlock = true;
    }
  }

  noViewModels() {
    this.viewModelsBlock = false;
  }
  
  onNavChange($event: any){
  }

  backModels() {
    this.viewModelsBlock = false;
    this.viewModelsCars = false;
  }
  backCars() {
    this.itemsGrupo = [];
    this.viewModelsBlock = true;
    this.viewModelsCars = false;
  }
  closeModels() {

  }
  closeCars() {
    this.itemsGrupo = [];
    this.viewModelsBlock = false;
    this.viewModelsCars = false;
  }
}
