<div class="footer">
    <div class="container">
        <div class="row footer-top">
            <div class="col-12 col-md-6 col-xl-3">
                <img [src]="imglogo" width="48" height="30" class="logos" alt="Logo toyota"/>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
                
            </div>
            <div class="col-12 col-md-6 col-xl-6 mr-auto">
                
            </div>
        </div>
        <div class="row footer-items">
            <div class="col-12 col-md-6 col-xl-3">
                <h3 class="title-items">VEHÍCULOS</h3>
                <div class="decoration-red"></div>
                <ul  class="items">    
                    <li *ngFor="let nombre of grupoModelos; index as i"><a routerLink="vehiculos/{{nombre}}"  class="grupos">{{nombre}}</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
                <h3 class="title-items">SERVICIOS</h3>
                <div class="decoration-red"></div>
                <ul class="items">
                    <li><a routerLink="/mantenimiento" class="link-foot">Mantenimientos y Reparaciones</a></li>
                    <!--<li><a href="https://www.toyosa.com/servicio-tecnico" target="_blank" class="link-foot">Agendado de Citas<img src="../../assets/fonts/footer/external-link-black.svg" alt="icono-link-externo" class="icon-external"/></a></li>-->
                    <li><a routerLink="/llamado-revision" class="link-foot">Campañas</a></li>
                    <li><a href="https://www.toyosa.com/tienda-virtual" target="_blank" class="link-foot">Repuestos<img [src]="imgexternalink" width="13" height="13" alt="icono-link-externo" class="icon-external"/></a></li>
                    <li><a href="https://www.toyosa.com/servicio-tecnico" target="_blank" class="link-foot">Agendar cita<img [src]="imgexternalink" width="13" height="13" alt="icono-link-externo" class="icon-external"/></a></li>
                </ul>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
                <h3 class="title-items">NOVEDADES</h3>
                <div class="decoration-red"></div>
                <ul class="items">
                    <li><a routerLink="/adn_historia" class="link-foot">Mundo toyota</a></li>
                    <li><a href="https://noticias.toyosa.com/" target="_blank" class="link-foot">Noticias<img [src]="imgexternalink" width="13" height="13" alt="icono-link-externo" class="icon-external"/></a></li>
                    <li><a href="/vehiculos-hibridos" class="link-foot">Híbridos Toyota</a></li>
                    <!-- <li><a href="https://www.toyota-latam.com/hibridos/" target="_blank" class="link-foot">Híbridos Toyota<img src="../../assets/fonts/footer/external-link-black.svg" alt="icono-link-externo" class="icon-external"/></a></li> -->
                    <li><a href="https://www.toyota.com/espanol/safety-sense/" target="_blank" class="link-foot">Safety Sense<img [src]="imgexternalink" width="13" height="13" alt="icono-link-externo" class="icon-external"/></a></li>
                    <li><a routerLink="/empieza-tu-imposible" class="link-foot">Start your Impossible</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
                <h3 class="title-items">HERRAMIENTAS DE <br/> COMPRA</h3>
                <div class="decoration-red"></div>
                <ul class="items">
                    <li><a routerLink="/cotizar" class="link-foot">Cotizar</a></li>
                    <li><a routerLink="/comparador-modelos" class="link-foot">Comparar</a></li>
                </ul>
            </div>
        </div>
        <div class="row footer-bottom">
            <div class="col-12 col-md-9 col-xl-9 rrss">
                <div class="title-rrss">
                    Síguenos en:
                    <div class="rrss-icons">
                        <a href="https://www.facebook.com/ToyotaBoliviaoficial" target="_blanck">
                            <img [src]="imgfacebook" width="40" height="40" alt="Facebook" class="icons-rs"/>
                        </a>
                        <a href="https://www.instagram.com/toyota_bolivia/" target="_blanck">
                            <img [src]="imginstagram" width="40" height="40" alt="Instagram" class="icons-rs"/>
                        </a>
                        <a href="https://youtube.com/channel/UCfYsmVjP96GrQs0_XbdZoug" target="_blanck">
                            <img [src]="imgyoutube" width="40" height="40" alt="Youtube" class="icons-rs"/>
                        </a>
                        <a href="https://www.linkedin.com/company/toyota-bolivia/" target="_blanck">
                            <img [src]="imglinkedin" width="40" height="40" alt="LinkedIn" class="icons-rs"/>
                        </a>
                        <a href="https://www.tiktok.com/@toyota_bolivia" target="_blanck">
                            <img [src]="imgtiktok" width="40" height="40" alt="Tiktok" class="icons-rs"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 col-xl-3">
                <a href="tel:591800175200" target="_blanck" >
                    <img [src]="imgcontact" width="204" height="90" alt="Contact center" class="icons-video"/>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="copyright">
    <div class="container">
        <div class="row footer-copy">
            <div class="col-4 col-md-6 col-xl-2">
                <div class="item-copy">
                    <a routerLink="/politicas-de-privacidad" class="link-foot">POLÍTICA DE PRIVACIDAD</a>
                </div>
            </div>
            <div class="col-4 col-md-6 col-xl-2">
                <div class="item-copy">
                    <a routerLink="/terminos-condiciones" class="link-foot">TÉRMINOS Y CONDICIONES</a>
                </div>
            </div>
            <div class="col-4 col-md-6 col-xl-2">
                <div class="item-copy">
                    <a routerLink="/mapa-del-sitio" class="link-foot">MAPA DE SITIO</a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6 txt-copy">
                © {{anio}} <a class="foot-copyright_link" href="https://www.toyosa.com" target="blank">Toyosa</a> copyright. all rights reserved
            </div>
        </div>
    </div>
</div>