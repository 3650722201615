import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { first } from 'rxjs';
import { VehiculosService } from './servicios/vehiculos.service';
import { DataService } from './servicios/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public renderer: Renderer2;
  loadingHeader = true;
  loadingFooter = true;
  loadingcookies = true;
  loadingwidget = true;

  constructor(private vehiculosService: VehiculosService, private dataService: DataService, rendererFactory: RendererFactory2, @Inject(DOCUMENT) private _document: Document) {
    this.obtenerVehiculos();
    this.renderer = rendererFactory.createRenderer(null, null);
    this.addSchema();
  }

  ngOnInit() {
    // Simula la carga de datos
    setTimeout(() => {
      this.loadingHeader = false;
      this.loadingFooter = false;
      this.loadingcookies = false;
      this.loadingwidget = false;
    }, 200); // Cambia 2000 por el tiempo real de carga
  }
  
  addSchema(){
    let elemento = this._document.getElementById('toyotahead');
    let script = this.renderer.createElement('script');
    script.type = `application/ld+json`;
    script.text = `{
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: 'https://www.toyota.bo/',
      name: 'Toyosa S.A',
      alternateName:'Toyota Bolivia',
      description:'Toyosa S.A. es distribuidor exclusivo de la marca de vehículos Toyota en Bolivia, comercializando productos y servicios de calidad.',
      contactPoint: [
        {
        '@type': 'ContactPoint',
        telephone: '591800175200',
        contactType: 'Customer service',
        areaServed: "BO",
        availableLanguage: "es"
        },
        {
        '@type': 'ContactPoint',
        telephone: '"5913342 5227',
        contactType: 'Customer service',
        areaServed: "BO",
        availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '59133499700',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '59144833490',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '59122784431',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '59155270847',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '59133888798',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '59144796062',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '5912239093035',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
        {
          '@type': 'ContactPoint',
          telephone: '59178622654',
          contactType: 'Customer service',
          areaServed: "BO",
          availableLanguage: "es"
        },
      ],
      sameAs: [
        'https://www.facebook.com/ToyotaBoliviaoficial',
        'https://www.instagram.com/toyota_bolivia/',
        'https://www.youtube.com/channel/UCfYsmVjP96GrQs0_XbdZoug',
        'https://www.linkedin.com/company/toyota-bolivia/',
        'https://www.tiktok.com/@toyota_bolivia'
        
        ]
    };`;
    this.renderer.appendChild(elemento, script);
  }


  // Servicio que obtiene la lista de vehiculos
  obtenerVehiculos():void {
    this.vehiculosService.getAutomovilesAll().pipe(first()).subscribe(
        (data:any) => {
          localStorage.setItem('vehiculos-header',JSON.stringify(data));
          let dataCompleta = JSON.stringify(data);
          this.dataService.modelos$.emit(dataCompleta);
        }, (error:any) => {
          // En caso de algún error notificamos
          // this.notificaciones.error(
          //     'Error: ',
          //     `Ha ocurrido un error: ${error}`
          // );
        }
    );  
  }

}
