<div class="mobile" (onscroll)="myScroll($event)">
    <div id='top-header' class="top-header" *ngIf="topHeader===0">
        <div class="container frase">
            <div class="row">
                <div class="col-12 font-toyota text-mkt">
                    <div class="menu-top-left">
                        <a href="https://www.kinto-mobility.com.bo/" target="_blanck" class="nav-link" (click)="noViewMenuPrincipal()">Kinto <img src="../../assets/fonts/header/sign.svg" class="external-link_menu"/></a>
                    </div>
                    <span class="separator">|</span>
                    <div class="menu-top-right">
                        <a href="https://www.seminuevos.toyota.bo/" target="_blanck" class="nav-link" (click)="noViewMenuPrincipal()">Seminuevos <img src="../../assets/fonts/header/sign.svg" class="external-link_menu"/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id='header' class="header_mobile">
        <div class="menu-fixed">
            <div class="container header-menu_mobile" *ngIf="!viewModelsBlock">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" routerLink="">
                        <img src="../../assets/images/logo.png" alt="Logo Toyota" class="logo"/>
                    </a> 
                    <ul class="navbar-nav ml-auto mt-2 mr-4">
                        <li ngbDropdown class="nav-item">
                            <a class="nav-link item-toyota" id="vehiculos" ngbDropdownToggle (click)="viewModels()">Vehículos</a>                  
                        </li>
                    </ul>           
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="viewMenuPrincipal()">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>
            <div class="menu-principal" *ngIf="menuPrincipal">
                <div class="row" >
                    <div class="col-9 menu-principal_items">
                        <div class="row pt-4 pb-4 brand-principal" *ngIf="!search">
                            <a class="col-4 pl-5 navbar-brand_principal" routerLink="">
                                <img src="../../assets/images/logo.png" alt="Logo Toyota" class="logo_principal"/>
                            </a> 
                            <div class="col-8">
                                <a routerLink="buscador" class="linkb">
                                    <img src="../../../assets/icons/header/buscador-negro.svg"/>
                                </a>
                            </div>
                        </div>
                        <ul class="nav flex-column " *ngIf="!search">
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="noViewMenuPrincipal()" routerLink="/gazoo-racing">Gazoo Racing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="noViewMenuPrincipal()" routerLink="/vehiculos-hibridos">Vehículos Híbridos</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="noViewMenuPrincipal()" href="https://www.seminuevos.toyota.bo/">Seminuevos <img src="../../assets/fonts/header/external-link-black.svg" alt="Link externo" class="external-link_menu"/></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="isCollapsedPostVenta = !isCollapsedPostVenta" [attr.aria-expanded]="!isCollapsedPostVenta" aria-controls="collapsePostVenta">
                                    Servicios
                                </a>
                                <div id="collapsePostVenta" [ngbCollapse]="!isCollapsedPostVenta">
                                    <ul class="nav flex-column pl-3 submenu-toyota">
                                        <li class="nav-item sub-item_toyota"><a class="nav-link" routerLink="/mantenimiento"  (click)="noViewMenuPrincipal()">Mantenimientos y Reparaciones</a></li>
                                        <li class="nav-item sub-item_toyota"><a class="nav-link" routerLink="/llamado-revision"  (click)="noViewMenuPrincipal()">Campañas</a></li>
                                        <li class="nav-item sub-item_toyota"><a class="nav-link" href="https://www.toyosa.com/tienda-virtual" target="_blanck" style="display: flex;" (click)="noViewMenuPrincipal()">Repuestos  <img src="../../assets/fonts/header/external-link-black.svg"  alt="Link externo" class="external-link_menu"/></a></li>
                                    </ul>
                                </div>
                            </li>
                            
                            <!--    QUITANDO EL SUBMENU ok -->
                            <!--    QUITANDO EL SUBMENU ok -->
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="isCollapsedSobreNosotros = !isCollapsedSobreNosotros" [attr.aria-expanded]="!isCollapsedSobreNosotros" aria-controls="collapseSobreNosotros">
                                    Quiénes Somos
                                </a>
                                <div id="collapseSobreNosotros" [ngbCollapse]="!isCollapsedSobreNosotros">
                                    <ul class="nav flex-column pl-3 submenu-toyota">
                                        <li class="nav-item sub-item_toyota"><a class="nav-link" routerLink="/nosotros" (click)="noViewMenuPrincipal()">Sobre Nosotros</a></li>
                                        <!--<li class="nav-item sub-item_toyota"><a class="nav-link" href="https://api-res.toyosa.com/pdf/memoria-2020-toyota.pdf" download="toyota-bolivia.pdf" target="_blank" (click)="noViewMenuPrincipal()">Memoria Digital 2020</a></li>-->
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="noViewMenuPrincipal()" routerLink="/sucursales">Sucursales</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="noViewMenuPrincipal()" routerLink="/contactenos">Contáctanos</a>
                            </li>
                        </ul>
                        <div class="row separador-menu" *ngIf="!search">
                        </div>
                        <ul class="nav flex-column " *ngIf="!search">
                            <li class="nav-item">
                                <a routerLink="/adn_historia" class="nav-link item-toyota" (click)="noViewMenuPrincipal()">Mundo Toyota</a>
                            </li>
                            <li class="nav-item">
                                <a href="https://noticias.toyosa.com/" target="_blanck" class="nav-link item-toyota" (click)="noViewMenuPrincipal()">Noticias <img src="../../assets/fonts/header/external-link-black.svg"  alt="Link externo" class="external-link_menu"/></a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/cotizar" class="nav-link item-toyota" (click)="noViewMenuPrincipal()"><span>Cotizar</span></a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/comparador-modelos" class="nav-link item-toyota" (click)="noViewMenuPrincipal()">Comparar</a>
                            </li>
                        </ul>
                        <div class="row section-search"  *ngIf="search">
                            <div class="col-2 control-left" (click)="noViewSearch()"><img src="../../../assets/fonts/header/left-red.svg" alt="Navegación" class="icon-menu"/></div>
                            <div class="col-8">Búsqueda</div>
                            <div class="col-2 control-right" (click)="noViewMenuPrincipal()"><img src="../../../assets/fonts/header/close-red.svg" alt="Navegación"class="icon-menu"/></div>
                        </div>
                        <!--<div class="row section-search_options">
                            <div class="input-group search">
                                <div class="input-group-prepend">
                                    <a class="input-group-text search-btn">
                                        <svg width="1em" height="1em" viewBox="0 0 17 17" class="bi bi-search text-danger" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                            <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                        </svg>
                                    </a>
                                </div>
                                <input type="text" class="form-control search-input" id="inlineFormInput" [(ngModel)]="search" placeholder="Buscar..">    
                            </div>       
                            
                        </div> -->
                        
                        <div class="row menu-search" *ngIf="search">
                            <div class="container">
                                <app-buscador [search]="search" class="row component-search"></app-buscador>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 menu-principal_back">
                        <a (click)="noViewMenuPrincipal()" class="opacity-block"></a>
                    </div>
                </div>  
                
                
            </div>
            <div class="row menu-models" *ngIf="viewModelsBlock && !search">
                <div class="container menu-models_groups" id="submenu-modelos">
                    <div class="row title-vehiculos">
                        <div class="col-2" (click)="backModels()"><img src="../../../assets/fonts/header/left-red.svg" alt="Navegación" class="icon-menu"/></div>
                        <div class="col-8 title-menu">Vehículos</div>
                        <div class="col-2" (click)="backModels()"><img src="../../../assets/fonts/header/close-red.svg" alt="Cerrar" class="icon-menu"/></div>
                    </div>
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" [orientation]="orientationTab" (navChange)="onNavChange($event)" class="nav-tabs menu-models_items">
                        <li *ngFor="let nombre of grupoModelos; index as i" [ngbNavItem]="i" (click)="obtenerItems(nombre)" class="items-group_toyota">
                            <a ngbNavLink class="nombre-grupo item-toyota font-toyota">{{nombre}} <img src="../../../assets/fonts/header/right-red.svg" alt="Navegación" class="in-link"/></a>
                        </li>
                    </ul>  
                </div>   
                
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>    
    
            </div>
            <div class="container models-items" *ngIf="itemsGrupo && (viewModelsCars===true)">
                <div class="row title-vehiculos">
                    <div class="col-2" (click)="backCars()"><img src="../../../assets/fonts/header/left-red.svg" alt="Navegación" class="icon-menu"/></div>
                    <div class="col-8 title-menu">{{nombreGrupo}}</div>
                    <div class="col-2" (click)="closeCars()"><img src="../../../assets/fonts/header/close-red.svg" alt="Cerrar" class="icon-menu"/></div>
                </div>
                <div class="row">
                    <div *ngFor="let items of itemsGrupo; index as mod" class="col-12 mt-1 mb-4">
                        <a routerLink="modelo/{{items.modelo_url}}" (click)="closeCars()"><img [src]="items.url_imagen" [alt]="items.nombre_modelo" class="models-items_img"/></a>
                        <div class="row pl-1 mt-2">
                            <h3 class="col-12 items-name">{{items.nombre_modelo}}</h3>
                            <div class="col-12">
                                <div class="separator-horizontal"></div>
                            </div>
                        </div>
                        <div class="row pl-3 mt-3">
                            <div class="col-5 ml-4 btn-models">
                                <a routerLink="modelo/{{items.modelo_url}}" class="items-link font-toyota" (click)="closeCars()">EXPLORAR</a>
                            </div>
                            <div class="col-1 separator-menu">
                                |
                            </div>
                            <!--<div class="separator-vertical"></div>-->
                            <div class="col-5 btn-models">
                                <a href="/cotizar/{{items.modelo_url}}" class="items-link font-toyota" (click)="closeCars()">COTIZAR</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            
        </div>
    </div>    
</div>
