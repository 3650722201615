<div class="skeleton" *ngIf="loadingHeader">
    <div class="skeleton-avatar"></div>
    <div class="skeleton-line"></div>
    <div class="skeleton-line"></div>
</div>
<app-nav-header class="header-nav" *ngIf="!loadingHeader"></app-nav-header>
<router-outlet></router-outlet>
<div class="skeleton" *ngIf="loadingFooter">
    <div class="skeleton-avatar"></div>
    <div class="skeleton-line"></div>
    <div class="skeleton-line"></div>
</div>
<app-footer-toyota *ngIf="!loadingFooter"></app-footer-toyota>
<!-- <app-notificaciones></app-notificaciones> -->
<div class="skeleton" *ngIf="loadingcookies">
    <div class="skeleton-transparent"></div>
    <div class="skeleton-transparent"></div>
    <div class="skeleton-transparent"></div>
</div>
<app-cookies-alert *ngIf="!loadingcookies"></app-cookies-alert>
<div class="skeleton" *ngIf="loadingwidget">
    <div class="skeleton-transparent wd"></div>
</div>
<app-widget-rrss *ngIf="!loadingwidget"></app-widget-rrss>


<!-- <app-popup-dinamico></app-popup-dinamico> -->
