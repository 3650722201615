import { Component, OnInit, HostListener, OnDestroy} from '@angular/core';
import { VehiculosService } from '../servicios/vehiculos.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {Subscription} from 'rxjs';
import { DataService } from '../servicios/data.service';
import { SeoService } from '../servicios/seo.service';
import { CloudinaryService } from '../servicios/cloudinary.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit, OnDestroy {

  dataCompleta:any=[];
  grupoModelos:any=[];
  itemsGrupo:any=[];
  active:any;
  viewModelsBlock:boolean=false;
  search: string;
  viewSearchBlock:boolean=false;

  topHeader:number=0;
  estadoSuscription: Subscription | undefined;

  logo:any;
  imgexternallink:any;
  imgexternallinkblack:any;

  constructor(private router: Router, private dataService: DataService, private cloudinaryService: CloudinaryService, private seoService: SeoService) {
      //this.obtenerVehiculos();
      this.active = null;
      this.search = '';

      this.loadImage();
  }
  loadImage() {
    this.logo = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721913723/production/toyota-bolivia/web/assets/icons/logo.png', {
      width: 64,
      height: 40,
      fetch_format: 'auto',
      quality: 'auto'
    });
    
    this.imgexternallink = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1722021583/production/toyota-bolivia/web/assets/icons/external-link-white.svg', {
      width: 13,
      height: 13,
      fetch_format: 'auto',
      quality: 'auto'
    });
    this.imgexternallinkblack = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721914261/production/toyota-bolivia/web/assets/icons/external-link-black.svg', {
      width: 14,
      height: 14,
      fetch_format: 'auto',
      quality: 'auto'
    });
  }

  ngOnInit(): void {
    this.estadoSuscription = this.dataService.modelos$.subscribe(estado => {
      let dataModelos = JSON.parse(estado);
      this.grupoModelos = Object.keys(dataModelos);
      this.dataCompleta = dataModelos;
      this.obtenerItems(this.grupoModelos[0]);
    });
    if(this.search === ''){
      this.viewSearchBlock = false;
    } else {
      this.viewSearchBlock = true;
    }
    this.seoService.createLinkForCanonicalURL();
    this.router.events.subscribe( (event: Event) => {

      if (event instanceof NavigationStart) {
        scrollTo(0, 0);
        this.viewModelsBlock = false;
        this.search = '';
        this.viewSearchBlock = false;
      }

      if (event instanceof NavigationEnd) {
        scrollTo(0, 0);
        this.viewModelsBlock = false;
        this.search = '';
        this.viewSearchBlock = false;
      }

      if (event instanceof NavigationError) {
        scrollTo(0, 0);
        this.viewModelsBlock = false;
        this.search = '';
        this.viewSearchBlock = false;
        console.log(event.error);
      }
    });
  }

  ngOnDestroy(): void {
    this.estadoSuscription?.unsubscribe();
  }


  @HostListener('window:scroll', ['$event'])
  myScroll($event: any) {
    this.topHeader = window.scrollY;

    if(this.topHeader > 0) {
      let elemento:any  = document.getElementById("header");
      elemento.style.top = "0px !important";
    }
  }
  
  obtenerItems(index: string) {
    this.itemsGrupo = this.dataCompleta[index].modelos;
  }

  viewModels() {
    if(this.viewModelsBlock){
      this.viewModelsBlock = false;
    } else {
      this.viewModelsBlock = true;
    }
  }
  noViewModels() {
    this.viewModelsBlock = false;
  }

  onNavChange($event: any){
  }
}
