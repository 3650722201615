import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { CloudinaryService } from '../servicios/cloudinary.service';

@Component({
  selector: 'app-widget-rrss',
  templateUrl: './widget-rrss.component.html',
  styleUrls: ['./widget-rrss.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        width: '100px',
        height: '100px'
      })),
      transition('void <=> *', animate(1000)),
    ]),
    trigger('flyInOut', [
      state('in', style({ transform: 'translateY(0)' })),
      transition('void => *', [
        style({ transform: 'translateY(100%)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'translateY(-100%)' }))
      ])
    ]),
    
  ]
})
export class WidgetRrssComponent {
  visible=false;

  imgwhatsapp:any;
  imgfacebook:any;
  imgllamada:any;
  imgcerrarchat:any;
  imgchat:any;
  imgrenuevate:any;

  constructor(private cloudinaryService: CloudinaryService) {
    this.loadImage();
  }

  loadImage() {
    this.imgwhatsapp = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721922009/production/toyota-bolivia/web/assets/icons/widget-whatsapp.svg', {
      width: 40,
      height: 40,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.imgfacebook = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721922022/production/toyota-bolivia/web/assets/icons/widget-msn.svg', {
      width: 40,
      height: 40,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.imgllamada = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721922041/production/toyota-bolivia/web/assets/icons/widget-llamada.svg', {
      width: 40,
      height: 40,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.imgcerrarchat = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721922048/production/toyota-bolivia/web/assets/icons/widget-cerrar-rojo.svg', {
      width: 40,
      height: 40,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.imgchat = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721922059/production/toyota-bolivia/web/assets/icons/widget-chat.svg', {
      width: 40,
      height: 40,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.imgrenuevate = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1721922074/production/toyota-bolivia/web/assets/icons/widget-renuevate.svg', {
      width: 40,
      height: 40,
      fetch_format: 'auto',
      quality: 'auto'
    });
  }

  open() {
    this.visible = true;
  }

  close() {
      this.visible = false;
  }
  onmousemove() {
    this.visible = true;
    setTimeout(() => {
      this.visible = false;
    }, 5000);
    
  }
}
