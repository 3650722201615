import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  

  constructor(private title: Title, @Inject(DOCUMENT) private doc: any, private sanitizer: DomSanitizer) {
    
  }


  setPageTitle(title: string) {
     this.title.setTitle(title);
  }   
  getPageTitle() {
     return this.title.getTitle();
  }
  createLinkForCanonicalURL() {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }
}
