import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class VehiculosService {

  constructor(private http: HttpClient) { }

  // Servicio que obtiene la lista de todos vehiculos
  getAutomovilesAll() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    //agregar v2
    const url = `${environment.base_url__v2}/toyota/automoviles/all`;
    return this.http.get<any>(url, httpOptions);
  }


  // Servicio que obtiene los datos de cada vehiculo
  getAutomoviles(modeloKey:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url__v1}/toyota/automoviles/subcategorias/${modeloKey}/caracteristicas`;
    //const url = `${environment.base_url__v1}/toyota/automoviles/subcategorias/hilux-19/caracteristicas`;
    return this.http.get<any>(url, httpOptions)
  }

  // Servicio que obtiene la lista de todos vehiculos
  getAutomovilesAllV1() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    //agregar v1
    const url = `${environment.base_url__v1}/toyota/automoviles/all`;
    return this.http.get<any>(url, httpOptions);
  }

  // Servicio que obtiene los datos de cada vehiculo
  comparador(idModelo:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url__v1}/vehiculo/comparador/${idModelo}`;
    return this.http.get<any>(url, httpOptions)
  }

}
