import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CookiesAlertRoutingModule } from './cookies-alert-routing.module';
import { CookiesAlertComponent } from './cookies-alert.component';


@NgModule({
  exports: [CookiesAlertComponent],
  declarations: [
    CookiesAlertComponent
  ],
  imports: [
    CommonModule,
    CookiesAlertRoutingModule
  ]
})
export class CookiesAlertModule { }
