<div id="cookies-alert" *ngIf="visible">
    <div class="container-fluid">
        <div class="row d-flex justify-content-center align-items-centerS" >
            <div class="col-10" id="close-cookies-alert">
                <span (click)="handleOkCookiesAlert(false)">Cerrar</span>
            </div>
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <p>Te informamos que para crear una mejor experiencia hacemos uso de cookies, para mas información de que ocurre con tus datos puedes revisar nuestras <a routerLink="/terminos-condiciones">Políticas de privacidad</a></p>
            </div>
            <div class="col-10 col-sm-4 col-md-4 col-lg-4 col-xl-3 mb-xl-3" style="text-align: center;">
                <button class="cookies-alert-btn align-items-center" (click)="handleOkCookiesAlert(true)">Sí, estoy de acuerdo</button>
            </div>
        </div>
    </div>
</div>

