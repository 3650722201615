import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-alert',
  templateUrl: './cookies-alert.component.html',
  styleUrls: ['./cookies-alert.component.css']
})
export class CookiesAlertComponent implements OnInit {

  visible!: boolean;

  constructor() { }

  cookiesAlert (){
    const servicio = localStorage.getItem('stateCookiesAlert');
    if (servicio) {
      if (servicio === 'aceptado') {
        this.visible = false;
      } else {
        this.visible = true;
      }
    } else {
      localStorage.setItem('stateCookiesAlert', 'no_aceptado');
      this.visible = true;
    }
  }

  handleOkCookiesAlert(confirmacion: boolean) {
    if (confirmacion) {
      this.visible = false;
      localStorage.setItem('stateCookiesAlert', 'aceptado');
    } else {
      this.visible = false;
      localStorage.setItem('stateCookiesAlert', 'no_aceptado');
    }
  }

  ngOnInit() {
    this.cookiesAlert();
  }


}
