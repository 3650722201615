export const environment = {
    production: false,
    base_url__v1: 'https://ws-toyosa.toyosa-test.com/api/v1',
    base_url__v2: 'https://ws-toyosa.toyosa-test.com/api/v2',
    base_url_servidor: 'https://ws-toyosa.toyosa-test.com',
    api_key_maps: 'AIzaSyBk6-zusArk27Q-a6ZPp44iLFmQG_ckXSA',
    recaptcha: {
        siteKey: '6LcCEw0TAAAAAKoeyWMIteGc_-6AiOVBa8cUb3ME',
    },
};
